import * as yup from 'yup';
import { ERROR_MESSAGES, LIMITS } from 'utils/form';
import { COUPON_POOL_TARGET_CHANNEL } from 'pages/Coupons/Form/Create/constants';

enum TEMPLATE_TYPE {
  BLANK = 'blank',
  WITH_IMAGE = 'with_image',
  B2B = 'b2b',
}

const BODY_SCHEMA = yup.object({
  label: yup.string().required(ERROR_MESSAGES.required),
  qrCode: yup.string().required(ERROR_MESSAGES.required),
  defaultCouponValue: yup
    .number()
    .typeError(ERROR_MESSAGES.invalidNumberType)
    .required(ERROR_MESSAGES.required),
  targetChannel: yup.string().required(ERROR_MESSAGES.required),
  maxCouponsPerUser: yup
    .number()
    .typeError(ERROR_MESSAGES.invalidNumberType)
    .required(ERROR_MESSAGES.required),
  minDelayBetweenCouponAssignments: yup
    .number()
    .min(
      LIMITS.couponPool.minDelayBetweenCouponAssignments,
      ERROR_MESSAGES.couponPool.minDelayBetweenCouponAssignments,
    )
    .typeError(ERROR_MESSAGES.invalidNumberType)
    .required(ERROR_MESSAGES.required),
  alertIfTooFewCoupons: yup.boolean(),
  isShareable: yup.boolean(),
  displayTemplate: yup.string().required(ERROR_MESSAGES.required),
  textColor: yup
    .string()
    .nullable()
    .when('templateType', {
      is: (templateType: string) => templateType !== TEMPLATE_TYPE.B2B,
      then: (schema) => schema.required(ERROR_MESSAGES.required),
    }),
  textBgColor: yup
    .string()
    .nullable()
    .when('templateType', {
      is: (templateType: string) => templateType !== TEMPLATE_TYPE.B2B,
      then: (schema) => schema.required(ERROR_MESSAGES.required),
    }),
  borderColor: yup
    .string()
    .nullable()
    .when('templateType', {
      is: (templateType: string) => templateType === TEMPLATE_TYPE.BLANK,
      then: (schema) => schema.required(ERROR_MESSAGES.required),
    }),
  linkColor: yup
    .string()
    .nullable()
    .when('templateType', {
      is: (templateType: string) => templateType !== TEMPLATE_TYPE.B2B,
      then: (schema) => schema.required(ERROR_MESSAGES.required),
    }),
  bgImage: yup
    .mixed()
    .nullable()
    .when('templateType', {
      is: (templateType: string) => templateType !== TEMPLATE_TYPE.BLANK,
      then: (schema) => schema.required(ERROR_MESSAGES.required),
    }),
  modalImage: yup
    .mixed()
    .nullable()
    .when('templateType', {
      is: (templateType: string) => templateType !== TEMPLATE_TYPE.BLANK,
      then: (schema) => schema.required(ERROR_MESSAGES.required),
    }),
  linkName: yup.string().nullable(),
  link: yup.string().nullable(),
  bgImageData: yup.mixed().nullable(),
  modalImageData: yup.mixed().nullable(),
});

const DEFAULT_VALUES = {
  label: '',
  qrCode: '',
  defaultCouponValue: 10,
  targetChannel: COUPON_POOL_TARGET_CHANNEL.BOTH,
  maxCouponsPerUser: 999999,
  minDelayBetweenCouponAssignments: 0,
  alertIfTooFewCoupons: false,
  isShareable: false,
};

const DEFAULT_TEMPLATE_VALUES: {
  [key: string]: any;
} = {
  [TEMPLATE_TYPE.BLANK]: {
    textColor: '#000000',
    textBgColor: '#ffffff',
    borderColor: '#EDEDED',
    linkColor: '#0B24FB',
    linkName: '',
    link: '',
    bgImage: null,
    modalImage: null,
  },
  [TEMPLATE_TYPE.B2B]: {
    textColor: '',
    textBgColor: '',
    borderColor: '',
    linkColor: '',
    linkName: '',
    link: '',
    bgImage: null,
    modalImage: null,
  },
  [TEMPLATE_TYPE.WITH_IMAGE]: {
    textColor: '#000000',
    textBgColor: '#ffffff',
    borderColor: '',
    linkColor: '#0B24FB',
    linkName: '',
    link: '',
    bgImage: null,
    modalImage: null,
  },
};

type FormData = yup.InferType<typeof BODY_SCHEMA>;

export { TEMPLATE_TYPE, BODY_SCHEMA, DEFAULT_VALUES, DEFAULT_TEMPLATE_VALUES };
export type { FormData };
